<template>
  <Ticket type="verify"/>
</template>

<script>
import Ticket from './ticket.vue'
export default {
  name: 'Verify',
  components: { Ticket }
}
</script>

<style>

</style>